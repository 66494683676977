import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button } from 'element-react'
import { FaPlus, FaEdit, FaTrash, FaList } from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import ArticleStatusEnum from 'constants/articleStatusEnum'

const Actions = ({
  row,
  handleEditClick,
  handleDelete,
  handleRevisionClick,
}) => {
  const history = useHistory()
  const getActions = () => {
    let actions = []

    if (row.Status === ArticleStatusEnum.NEW) {
      actions.push(
        <Button size="mini" onClick={() => handleEditClick(row)}>
          <FaEdit />
          Edit
        </Button>,
      )
    }

    if (row.Status === ArticleStatusEnum.REVISION && row) {
      actions.push(
        <Button size="mini" onClick={() => handleRevisionClick(row)}>
          <MdRefresh />
          Revise
        </Button>,
      )
    }
    if (row.Status === ArticleStatusEnum.NEW) {
      actions.push(
        <Button size="mini" onClick={() => handleDelete(row)}>
          <FaTrash />
          Delete
        </Button>,
      )
    }

    let div = (
      <div className="table-actions">
        <ul style={{ display: 'flex' }}>{actions}</ul>
      </div>
    )
    return actions.length > 0 ? div : null
  }

  return <>{getActions()}</>
}

export default Actions
