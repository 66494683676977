import { Form } from "element-react";
import { isEmpty } from "helpers";
import React from "react";
import { useSelector } from "react-redux";
const Start = (props) => {
  const { checkList, templates, sections } = useSelector(
    (state: any) => state.metaReducer
  );

  const { article } = useSelector((st: any) => st.articleReducer);

  const copyrightStatements = templates.find(
    (x) => x.TemplateKey === "copyrightStatements"
  );

  const privacyStatements = templates.find(
    (x) => x.TemplateKey === "privacyStatements"
  );
  if (isEmpty(article)) return null;
  return (
    <div className="viewArticle__start">
      <Form className="demo-form-stacked" labelPosition="top" labelWidth="100">
        <Form.Item
          label={copyrightStatements && copyrightStatements.TemplateTitle}
        >
          <div>{copyrightStatements && copyrightStatements.TemplateText}</div>
        </Form.Item>
        <Form.Item label="Section">{article && article.SectionName}</Form.Item>
        <Form.Item label="CheckList">
          <ul>
            {article.CheckList.map((x, index) => (
              <li key={index}>{x.CheckListName}</li>
            ))}
          </ul>
        </Form.Item>
        <Form.Item label="Comments for the Editor">
          <div
            className="ck-editor-content"
            dangerouslySetInnerHTML={{ __html: article.CommentsForEditor }}
          ></div>
        </Form.Item>
        <Form.Item label={privacyStatements && privacyStatements.TemplateTitle}>
          <div className="ck-editor-content">
            {privacyStatements && privacyStatements.TemplateText}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Start;
