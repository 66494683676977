import React from "react";
import { Form, Input, Button, Select, Dialog } from "element-react";
import { Link } from "react-router-dom";
import RouteEnum from "constants/routesEnum";

const SearchFilter = (props) => {
  const { state, setState, onSearchClicked } = props;

  const onChangeSearch = (name, val) => {
    setState({ ...state, [name]: val });
  };
  return (
    <div className="submission-search">
      <Form inline={true}>
        <Form.Item>
          <Input
            style={{ width: "250px" }}
            value={state.proposalTitle}
            placeholder="Article Ttile"
            onChange={(val: any) => onChangeSearch("proposalTitle", val)}
          ></Input>
        </Form.Item>
        <Form.Item>
          <Input
            style={{ width: "250px" }}
            value={state.submissionNumber}
            placeholder="Submission Number"
            onChange={(val: any) => onChangeSearch("submissionNumber", val)}
          ></Input>
        </Form.Item>

        <Form.Item>
          <Input
            style={{ width: "250px" }}
            value={state.section}
            placeholder="Section"
            onChange={(val: any) => onChangeSearch("section", val)}
          ></Input>
        </Form.Item>

        <Form.Item>
          <Input
            style={{ width: "250px" }}
            value={state.subSection}
            placeholder="Sub Section"
            onChange={(val: any) => onChangeSearch("subSection", val)}
          ></Input>
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={() => onSearchClicked()}>
            Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SearchFilter;
